import React from "react"
import loadable from "@loadable/component"

// Dynamically import the Hero component
const Hero = loadable(() => import("../components/NotFound/Hero.jsx"))

export default function PolicyPage() {
  return (
    <>
      <Hero />
    </>
  )
}
